@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

body {
  font-family: 'Montserrat';
  overflow-x: hidden;
}

.scroll-bar>.row {
  overflow-x: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.scroll-bar>.row>.col-lg-3,
.scroll-bar>.row>.col-lg-4 {
  display: inline-block;
  float: none;
}


::-webkit-scrollbar {
  width: 1.2rem;
  height: 0.7rem;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 0.5rem;
  border: 0.2rem solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

.simplebar-track.simplebar-horizontal {
  display: none;
}

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --h5-semibold: Montserrat;
  --font-inherit: inherit;

  /* font sizes */
  --h5-semibold-size: 1.56rem;
  --h6-regular-size: 1.25rem;
  --h4-semibold-size: 1.94rem;
  --h2-semibold-size: 3.06rem;
  --h3-semibold-size: 2.44rem;
  --h1-bold-size: 3.81rem;
  --base-bold-size: 1rem;
  --small-semibold-size: 0.81rem;

  /* Colors */
  --base-130: #fff;
  --primary-active: #176fde;
  --primary-selected: #135cb9;
  --primary-hover: #468fec;
  --info-soft: #e2f6fe;
  --base-20: #4d4d4d;
  --base-110: #f5f5f5;
  --base-50: #898989;
  --base-40: #757575;
  --base-00: #292929;
  --color-gray-100: rgba(255, 255, 255, 0.28);
  --info-hover: #2ac8f4;
  --info-active: #00baec;
  --info-selected: #0091b8;
  --base-90: #e2e2e2;
  --warning-active: #fabd48;
  --success-active: #00c23b;
  --color-black: #000;

  /* Gaps */
  --gap-base: 1rem;
  --gap-29xl: 3rem;
  --gap-5xl: 1.5rem;
  --gap-5xs: 0.5rem;

  /* Paddings */
  --padding-base: 1rem;
  --padding-77xl: 6rem;
  --padding-5xs: 0.5rem;
  --padding-37xl: 3.5rem;
  --padding-21xl: 2.5rem;
  --padding-5xl: 1.5rem;
  --padding-9xs: 0.25rem;
  --padding-xs: 0.75rem;

  /* Border radiuses */
  --br-9xs: 4px;
  --br-21xl: 40px;

  /* Effects */
  --effect: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


/* NEW  CODE */


/* .session-page-user-reaches-be-child,
.session-page-user-reaches-be-item {
  display: none;
  position: absolute;
  margin: 0 !important;
  top: -316px;
  left: -164px;
  border-radius: 50%;
  background-color: #fabd48;
  filter: blur(200px);
  width: 1116px;
  height: 1116px;
  opacity: 0.2;
  z-index: 0;
}

.session-page-user-reaches-be-item {
  top: 235px;
  display: none;
  left: 788px;
  background-color: #00baec;
  width: 800px;
  height: 800px;
  z-index: 1;
} */

.logo-event-icon {
  position: relative;
  width: 193.33px;
  height: 40px;
}

.presented-by-icon {
  position: relative;
  width: 77.6px;
  height: 8px;
}

.logo-caryfyai-icon {
  position: relative;
  width: 103.88px;
  /* height: 32px; */
}

.logo-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  min-width: 104px;
}

.ioniconcchatboxoutline {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.chat {
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
}

.header {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 24px;
  min-width: 328px;
  max-width: 1296px;
  z-index: 2;
}

.session-will-starts {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 600;
  font-family: inherit;
}

.h1,
.timer {
  align-self: stretch;
}

.h1 {
  margin: 0;
  position: relative;
  font-size: 96px;
  line-height: 120%;
  font-weight: 400;
  font-family: inherit;
  color: #292929;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.its-wonderful-to {
  flex: 1;
  position: relative;
  line-height: 120%;
  font-weight: 600;
}

.col-1,
.motivation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.motivation {
  align-self: stretch;
  border-radius: 4px;
  background-color: #fef8e2;
  flex-direction: row;
  padding: 40px;
}

.col-1 {
  flex: 1;
  flex-direction: column;
  gap: 32px;
  min-width: 328px;
}

.success-summit-,
.the-2nd-session {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}

.success-summit- {
  font-size: 52px;
  color: #176fde;
}

.event-title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 22px;
}

.vulputate-at-dignissim {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 120%;
  color: #757575;
}

.learning {
  align-self: stretch;
  border-left: 6px solid #8341ef;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 20px;
  gap: 4px;
}

.host-image-icon {
  position: relative;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  object-fit: cover;
}

.anand-chaturvedi {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}

.care-business-coach {
  margin: 0;
  position: relative;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
}

.host-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}

.col-2,
.host-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 18px;
}

.col-2 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  min-width: 328px;
  text-align: left;
  font-size: 20px;
}

.pre-session {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 96px;
  max-width: 1296px;
  z-index: 3;
}

.session-page-user-reaches-be {
  position: relative;
  /* background-image: url(".././src/assets/public/bg-dv.png")!important;
  background-size: cover!important; */
  width: 100%;
  /* height: 836px; */
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  box-sizing: border-box;
  gap: 48px;
  text-align: center;
  font-size: 22px;
  color: #4d4d4d;
  height: 100%;
}


.session-page-session-starts-child,
.session-page-session-starts-item {
  position: absolute;
  margin: 0 !important;
  top: -316px;
  left: -164px;
  border-radius: 50%;
  background-color: #fabd48;
  filter: blur(200px);
  width: 1116px;
  height: 1116px;
  opacity: 0.2;
  z-index: 0;
}

.session-page-session-starts-item {
  top: 235px;
  left: 788px;
  background-color: #00baec;
  width: 800px;
  height: 800px;
  z-index: 1;
}

.logo-event-icon1 {
  position: relative;
  width: 193.33px;
  height: 40px;
}

.presented-by-icon1 {
  position: relative;
  width: 77.6px;
  height: 8px;
}

.logo-caryfyai-icon1 {
  position: relative;
  width: 103.88px;
  /* height: 32px; */
}

.logo-wrap1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  min-width: 104px;
}

.ioniconcchatboxoutline1 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.ioniconccontractoutline-container {
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
}

.header1,
.video {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 328px;
}

.header1 {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 24px;
  max-width: 1296px;
  z-index: 2;
}

.video {
  border: 0;
  background-color: #292929;
  width: 960px;
  height: 600px;
  justify-content: center;
  min-height: 200px;
}

.comments1 {
  position: relative;
  line-height: 120%;
  font-weight: 600;
}

.title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.this-is-awesome,
.udaysutaria {
  position: relative;
  line-height: 120%;
}

.this-is-awesome {
  flex: 1;
  padding: 5px;
  border-radius: 5px;
}

.this-is-awesome {
  background: #e8f2ff;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 5px;
}

.ioniconaarrowundooutline {
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.reply {
  cursor: pointer;
  border: 0;
  padding: 4px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.item-comment,
.item-comment1 {
  align-self: stretch;
  /* border-bottom: 1px solid #e2e2e2; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0;
  gap: 4px;
}

.item-comment1 {
  padding: 0 0 12px;
}

.yes-you-can {
  position: relative;
  line-height: 120%;
  display: inline-block;
  width: 100%;
}

.item-reply {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 24px;
  gap: 4px;
}

.comment-wrap,
.input,
.item-has-reply,
.item-reply {
  display: flex;
  justify-content: flex-start;
}

.item-has-reply {
  align-self: stretch;
  border-bottom: 1px solid #e2e2e2;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0;
  gap: 12px;
}

.comment-wrap,
.input {
  flex: 1;
}

.comment-wrap {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
}

.input {
  border: 1px solid #e2e2e2;
  font-size: 14px;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
}

.ioniconssendoutline {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.comments,
.form-group,
.post {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.post {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #176fde;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  justify-content: center;
}

.comments,
.form-group {
  align-self: stretch;
  justify-content: flex-start;
  gap: 4px;
}

.comments {
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
}

.session-video {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 1296px;
  z-index: 3;
  height: 70%;
}

.session-page-session-starts {
  position: relative;
  width: 100%;
  /* height:95vh; */
  /* overflow: hidden;
  overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  box-sizing: border-box;
  gap: 48px;
  text-align: left;
  font-size: 18px;
  color: #4d4d4d;

}

/* Question_screen_CSS_start */
.session-page-session-has-que-child,
.session-page-session-has-que-item {
  position: absolute;
  margin: 0 !important;
  top: -316px;
  left: -164px;
  border-radius: 50%;
  background-color: #fabd48;
  filter: blur(200px);
  width: 1116px;
  height: 1116px;
  opacity: 0.2;
  z-index: 0;
}

.session-page-session-has-que-item {
  top: 235px;
  left: 788px;
  background-color: #00baec;
  width: 800px;
  height: 800px;
  z-index: 1;
}

.logo-event-icon2 {
  position: relative;
  width: 193.33px;
  height: 40px;
}

.presented-by-icon2 {
  position: relative;
  width: 77.6px;
  height: 8px;
}

.logo-caryfyai-icon2 {
  position: relative;
  width: 103.88px;
  /* height: 32px; */
}

.logo-wrap2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  min-width: 104px;
}

.ioniconcchatboxoutline3 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.ioniconccontractoutline-frame {
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
}

.header2 {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 24px;
  min-width: 328px;
  max-width: 1296px;
  z-index: 2;
}

.sit-sit-feugiat {
  align-self: stretch;
  position: relative;
  font-size: 28px;
  line-height: 120%;
  font-weight: 600;
}

.ioniconrradiobuttonoffout {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.nibh-id-vivamus {
  flex: 1;
  position: relative;
  line-height: 120%;
  font-weight: 600;
}

.ioniconrradiobuttonoffout-parent,
.item-question {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.item-question {
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.submit-your-answer {
  font-weight: 600;
}

.sec,
.submit-your-answer {
  position: relative;
  line-height: 120%;
}

.answer-timer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.submit1 {
  position: relative;
  font-size: 16px;
  line-height: 120%;
  font-weight: 600;
  color: #fff;
  text-align: left;
}

.submit {
  cursor: pointer;
  border: 0;
  padding: 12px 28px;
  background-color: #0091b8;
  border-radius: 4px;
  flex-direction: row;
  align-items: flex-start;
}

.action,
.question,
.submit {
  display: flex;
  justify-content: flex-start;
}

.action {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #0091b8;
}

.question {
  border-radius: 8px;
  background-color: #fff;
  width: 960px;
  height: 600px;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  gap: 32px;
  min-width: 328px;
  min-height: 200px;
}

.this-is-awesome1 {
  flex: 1;
  position: relative;
  line-height: 120%;
}

.ioniconaarrowundooutline3 {
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.reply3 {
  cursor: pointer;
  border: 0;
  padding: 4px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.item-comment3,
.item-comment4 {
  align-self: stretch;
  /* border-bottom: 1px solid #e2e2e2; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0;
  gap: 4px;
}

.item-comment4 {
  padding: 0 0 12px;
}

.yes-you-can1 {
  position: relative;
  line-height: 120%;
  display: inline-block;
  width: 100%;
}

.item-has-reply1,
.item-reply1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 24px;
  gap: 4px;
}

.item-has-reply1 {
  border-bottom: 1px solid #e2e2e2;
  padding: 12px 0;
  gap: 12px;
}

.comment-wrap1,
.input1 {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.comment-wrap1 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
}

.input1 {
  border: 1px solid #e2e2e2;
  font-size: 14px;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
}

.ioniconssendoutline1 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.comments2,
.form-group1,
.post1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.post1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #176fde;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  justify-content: center;
}

.comments2,
.form-group1 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 4px;
}

.comments2 {
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  font-size: 18px;
}

.session-question {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 1296px;
  z-index: 3;
}

.session-page-session-has-que {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  box-sizing: border-box;
  gap: 48px;
  text-align: left;
  font-size: 20px;
  color: #4d4d4d;
}

/* Question_screen_CSS_start */

























/* Custom-css_start */
body {
  font-family: 'Poppins', sans-serif;
}

.resposive-icons .row {
  display: none;
}

.resposive-icons>* div {
  margin-right: 6px;
}

.custom-header-dv {
  display: flex;
  justify-content: space-between;
}

.custom-header-dv .logo-wrap {
  width: 50%;
}

.success-summit-logo {
  position: relative;
}

.success-summit-logo .logo-event-icon {
  position: absolute;
  left: 0px;
  top: -10px;
}

.caryfy_logo {
  position: relative;
}

.caryfy_logo .inner_caryfy_logo {
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  min-width: 100%;
  top: -20px;
}

.logo-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  min-width: 104px;
}

.iframe_video {
  position: relative;
}

.overlay_question_dv {
  position: absolute;
  z-index: 9999999 !important;
  top: 0px;
  border-radius: 0px;

}

@media screen and (max-width: 1200px) {
  .chat {
    display: block;
  }

  /* .caryfy_logo {
    display: none;
  } */

  .resposive-icons .row {
    display: flex;
  }

  .custom-header-dv .logo-wrap {
    width: 50%;
    text-align: right;
  }

  .resposive-icons>* div {
    margin-right: 6px;
    padding: 9px 10px;
  }

  .resposive-icons>* div .ioniconcchatboxoutline {
    position: relative;
    width: 15px;
    height: 15px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-content: center;
  }

  .success-summit-logo .logo-event-icon {
    position: absolute;
    left: 0px;
    top: -20px;
  }
}

@media screen and (max-width: 960px) {
  .logo-wrap {
    display: block;
  }

  /* .caryfy_logo {
    display: none;
  } */

  .resposive-icons .row {
    display: flex;
    justify-content: right;
  }

  .custom-header-dv .logo-wrap {
    width: 50%;
    text-align: right;
  }

  .resposive-icons>* div {
    margin-right: 6px;
    padding: 9px 10px;
  }

  .resposive-icons>* div .ioniconcchatboxoutline {
    position: relative;
    width: 15px;
    height: 15px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-content: center;
  }

  .success-summit-logo .logo-event-icon {
    position: absolute;
    left: 0px;
    top: -20px;
  }

  .success-summit- {
    font-size: 35px;
    color: #176fde;
  }

  h6 {
    font-size: 19px !important;
  }

  p {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .header {
    padding: 10px !important;
  }

  .motivation {
    padding: 20px !important;
  }

  .its-wonderful-to p {
    flex: 1;
    position: relative;
    line-height: 120%;
    font-size: 19px !important;
    font-weight: 600;
  }

  .h1 {
    font-size: 50px !important;
    line-height: 60px !important;
  }


  .chat {
    display: inline-block;
  }

  .h1 {
    font-size: 64px;
    line-height: 120px;
  }
}

@media screen and (max-width: 480px) {
  .success-summit-logo .logo-event-icon {
    top: -20px;
    width: 60% !important;
  }

  .h1 {
    font-size: 40px !important;
    line-height: 58px !important;
  }

  .its-wonderful-to p {
    flex: 1;
    position: relative;
    line-height: 120%;
    font-size: 16px !important;
    font-weight: 600;
  }

  .success-summit- {
    font-size: 30px;
    color: #176fde;
  }

  h6 {
    font-size: 16px !important;
  }

  .resposive-icons>* div {
    margin-right: 1px;
    padding: 9px 10px;
  }
}

@media screen and (max-width: 360px) {
  .header {
    width: 100%;
  }

  .success-summit-logo .logo-event-icon {
    top: -20px;
    width: 85% !important;
  }

  .resposive-icons>* div {
    margin-right: -5px;
    padding: 9px 10px;
  }

  .resposive-icons .row {
    display: block;
  }

  .h1 {
    font-size: 48px;
    line-height: 120px;
  }

  .session-page-user-reaches-be {
    height: auto;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}

@media screen and (max-width:320px) {
  .session-page-user-reaches-be {
    height: auto;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }

  .header {
    width: 100%;
  }

  .success-summit-logo .logo-event-icon {
    top: -20px;
    width: 85% !important;
  }

  .resposive-icons>* div {
    margin-right: -5px;
    padding: 9px 10px;
  }

  .header {
    padding: 0px 20px 0px !important;
  }

  .resposive-icons>* div .ioniconcchatboxoutline {
    position: relative;
    width: 13px;
    height: 13px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-content: center;
  }

  .event-title {

    padding: 0px 24px;
  }

  .success-summit- {
    font-size: 25px;
    color: #176fde;
  }
}

/* Session-video_page_css_start */
.session-page-session-starts-child,
.session-page-session-starts-item {
  position: absolute;
  margin: 0 !important;
  top: -316px;
  left: -164px;
  border-radius: 50%;
  background-color: #fabd48;
  filter: blur(200px);
  width: 1116px;
  height: 1116px;
  opacity: 0.2;
  z-index: 0;
}

.session-page-session-starts-item {
  top: 235px;
  left: 788px;
  background-color: #00baec;
  width: 800px;
  height: 800px;
  z-index: 1;
}

.logo-event-icon1 {
  position: relative;
  width: 193.33px;
  height: 40px;
}

.presented-by-icon1 {
  position: relative;
  width: 77.6px;
  height: 8px;
}

.logo-caryfyai-icon1 {
  position: relative;
  width: 103.88px;
  /* height: 32px; */
}

.logo-wrap1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  min-width: 104px;
}

.ioniconcchatboxoutline1 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.ioniconccontractoutline-container {
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
}

.header1,
.video {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 328px;
}

.header1 {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 24px;
  max-width: 1296px;
  z-index: 2;
}

.video {
  border: 0;
  background-color: #292929;
  width: 960px;
  height: 600px;
  justify-content: center;
  min-height: 200px;
}

.comments1 {
  position: relative;
  line-height: 120%;
  font-weight: 600;
}

.title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.this-is-awesome,
.udaysutaria {
  position: relative;
  line-height: 120%;
}

.this-is-awesome {
  flex: 1;
}

.ioniconaarrowundooutline {
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.reply {
  cursor: pointer;
  border: 0;
  padding: 4px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.item-comment,
.item-comment1 {
  align-self: stretch;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0;
  gap: 4px;
}

.item-comment1 {
  padding: 0 0 12px;
}

.yes-you-can {
  position: relative;
  line-height: 120%;
  display: inline-block;
}

.item-reply {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 24px;
  gap: 4px;
}

.comment-wrap,
.input,
.item-has-reply,
.item-reply {
  display: flex;
  justify-content: flex-start;
}

.item-has-reply {
  align-self: stretch;
  border-bottom: 1px solid #e2e2e2;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0;
  gap: 12px;
}

.comment-wrap,
.input {
  flex: 1;
}

.comment-wrap {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
}

.input {
  border: 1px solid #e2e2e2;
  font-size: 14px;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
}

.ioniconssendoutline {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.comments,
.form-group,
.post {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.post {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #176fde;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  justify-content: center;
}

.comments,
.form-group {
  align-self: stretch;
  justify-content: flex-start;
  gap: 4px;
}

.comments {
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
}

.session-video {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 1296px;
  z-index: 3;
}

.session-page-session-starts {
  position: relative;
  /* background-color: #fff; */
  width: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  box-sizing: border-box;
  gap: 48px;
  text-align: left;
  font-size: 18px;
  color: #4d4d4d;
}

.ioniconrradiobuttonoffout-parent,
.item-question {
  align-items: center !important;
}

.testimonial-stories {
  background-image: url('./assets/public/testimonial-stories@3x.png');
}

.michelle-story {
  background-image: url("./assets/public/michelle-story@3x.png");
}

.testimonials1 {
  background-image: url("./assets/public/testimonial-stories@3x.png");
}

.next-step {
  background-image: url("./assets/public/next-step@3x.png");
}

.yout-pathway {
  background-image: url("./assets/public/yout-pathway@3x.png");
}

.testimonial-stories {
  background-image: url("./assets/public/testimonial-stories@3x.png");

}

.different-tomorrow {
  background-image: url("./assets/public/different-tomorrow@3x.png");
}

.join-conversation-dv {
  background-image: url("./assets/public/join-conversation-dv.png");
}

.blog-michelle-story {
  padding: 50px 0px !important;
  background: color #e2f6fe !important;
}

.blog-michelle-story .story-description {
  color: #176FDE !important;
}

.blog-michelle-story .story-description-wrapper {
  border-radius: 13px !important;
  font-weight: 300 !important;
}

/* Session-video_page_css_start */
/* Session-video_page_responsive-css_start */

@media screen and (max-width: 1200px) {
  .ioniconccontractoutline-container {
    display: none;
  }

}

@media screen and (max-width: 960px) {
  .logo-wrap1 {
    display: none;
  }

  .ioniconccontractoutline-container {
    display: inline-block;
  }

  .comments {
    display: none;
  }
}

@media screen and (max-width: 360px) {

  .header1,
  .video {
    width: 100%;
  }

  .video {
    height: auto;
    min-width: 328px;
    min-height: 200px;
  }

  .comments {
    flex: unset;
    align-self: stretch;
  }

  .session-video {
    flex-direction: column;
  }

  .session-page-session-starts {
    height: auto;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    padding: 0px;
  }
}

/* Session-video_page_responsive-css_start */
@media screen and (max-width: 1200px) {
  .ioniconccontractoutline-frame {
    display: none;
  }

  .item-question .sit-sit-feugiat h3 {
    align-self: stretch;
    position: relative;
    font-size: 23px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    line-height: 120%;
    font-weight: 600;
  }

  .question {
    padding: 40px;
  }

  .nibh-id-vivamus p {
    font-size: 19px;
  }

  .ioniconrradiobuttonoffout {
    position: relative;
    top: -6px;
  }

  .nibh-id-vivamus {
    flex: 1;
    position: relative;
    line-height: 120%;
    font-weight: 500;
    font-size: 16px;
  }
}

@media screen and (max-width: 991px) {
  .item-question .sit-sit-feugiat h3 {
    font-size: 20px;
  }

  .answer-timer {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 960px) {
  .logo-wrap2 {
    display: none;
  }

  .ioniconccontractoutline-frame {
    display: inline-block;
  }

  .comments2 {
    display: none;
  }

  .session-question {
    display: block !important;
  }

  .comments2 {
    min-width: 100% !important;
    width: 100% !important;
  }

  .question {
    min-width: 100% !important;
    width: 100% !important;
  }

  .item-question .sit-sit-feugiat h3 {
    font-size: 30px;
  }

  .nibh-id-vivamus {
    font-size: 20px;
  }

  .ioniconrradiobuttonoffout {
    position: relative;
    top: -3px;
  }
}

@media screen and (max-width: 480px) {
  .session-question {
    display: block !important;
  }

  .question,
  .comments2 {
    min-width: 100% !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 360px) {
  .header2 {
    width: 100%;
  }

  .session-page-session-has-que {
    height: auto;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }

  .session-question {
    display: block !important;
  }

  .question,
  .comments2 {
    min-width: 100% !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 320px) {
  .session-question {
    display: block !important;
  }

  .question,
  .comments2 {
    min-width: 100% !important;
    width: 100% !important;
  }
}





/* overlay_responsive design_css */
.social-btn-blg {
  border: 1px soldi
}

.overlay_question_dv h3 {
  margin: 0px !important;
}

.ioniconrradiobuttonoffout {
  position: relative;
  top: 0px !important;
}

.iframe_video {
  position: relative;
  min-width: 67%;
}

.comments {
  min-width: 30%;
  border-radius: 0px;
  /* height:70vh;   02-10-22*/
  position: relative;
  /* height: 100%; */
  height: 500px;
}

.iframe_video {
  width: 100% !important;
  /* height: 100%; */
  height: 500px;
}

.inner-comment-div {
  overflow-y: scroll;
  width: 100% !important;
  height: 100% !important;
  overflow-x: hidden !important;
}

.comments .form-group {
  position: absolute;
  min-width: 92%;
  bottom: 12px;
  background-color: #fff;
}

@media screen and (max-width: 1200px) {

  .iframe_video {
    position: relative;
    width: 70%;
    height: 500px;
  }

  .video {
    width: 100%;
  }

  .overlay_question_dv {
    border-radius: 0px;
    width: 100%;
  }

  .question {
    padding: 13px !important;
  }

  .comments {
    width: 30%;
    border-radius: 0px;
  }
}

@media screen and (max-width: 991px) {
  .page-wrap.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .inner-comment-div {
    padding-bottom: 80px;
  }

  .comments {
    border-bottom: none;
    overflow: hidden;
    padding-bottom: 29px;
  }

  .submit_blg_dv {
    position: sticky;
    background: #fff;
    height: auto !important;
    z-index: 9;
  }

  .comments .form-group {
    position: absolute;
    min-width: 100%;
    bottom: 0%;
    background: #fff;
    padding: 8px;
    max-width: 100%;
    width: 100vh !important;
    left: -17px;
  }

  .comments {
    width: 100% !important;
    margin-top: 20px;
    display: block !important;
  }

  .iframe_video {
    height: 400px !important;
  }

  .session-page-user-reaches-be {
    height: 100%;
    overflow-y: scroll;
    background-size: inherit !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;

  }

  .iframe_video,
  .session-video {
    display: block !important;
  }

  .comments {
    width: 100%;
    margin-top: 20px;
    /* height: 50vh !important; */
    height: 300px !important;
  }

  .inner-comment-div {
    overflow-y: scroll;
    width: 100% !important;
    /* height: 70% !important; */
  }

  .this-is-awesome,
  .udaysutaria {
    font-size: 14px !important;
  }

  .yes-you-can {
    font-size: 14px !important;
  }

}

@media screen and (max-width: 767px) {
  .page-wrap.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .comments {
    border-bottom: none;
    overflow: hidden;
    padding-bottom: 29px;
  }

  .submit_blg_dv {
    position: sticky;
    background: #fff;
    height: auto !important;
    z-index: 9 !important;
  }

  .comments .form-group {
    position: absolute;
    min-width: 100%;
    bottom: 0%;
    background: #fff;
    padding: 8px;
    max-width: 100%;
    width: 100vh !important;
    left: -17px;
  }

  .overlay_question_dv {
    border-radius: 0px;
    width: 100%;
    height: 100% !important;
  }

  .session-page-user-reaches-be {
    overflow-y: scroll;

  }

  .iframe_video {
    height: 400px !important;
  }

  .question {
    min-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
  }

  .item-question .sit-sit-feugiat h3 {
    font-size: 23px;
  }

  .ioniconrradiobuttonoffout {
    width: 16px;
    height: 16px;
    position: relative;
    top: -6px;
  }

  .iframe_video {
    width: 100% !important;
  }

  .answer-timer {
    display: block !important;
  }

}

@media screen and (max-width: 575px) {
  .page-wrap.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .sec,
  .submit-your-answer {
    position: relative;
    top: 15px !important;
  }

  .comments {
    border-bottom: none;
    overflow: hidden;
    padding-bottom: 23px;
  }

  .submit_blg_dv {
    position: sticky;
    background: #fff;
    height: auto !important;
    z-index: 9;
  }

  .comments .form-group {
    position: absolute;
    min-width: 92%;
    bottom: 0%;
    background: #fff;
    padding: 8px;
    max-width: 100%;
    width: 100vh !important;
    left: -17px;
  }

  .answer-timer {
    display: block !important;
  }

  /* .session-page-user-reaches-be{
    overflow-y: scroll;
} */
  .iframe_video {
    width: 100% !important;
    /* height: 40% !important; */
    height: 400px !important;

  }

  .this-is-awesome,
  .udaysutaria,
  .yes-you-can {
    font-size: 14px !important;
  }

  .session-video {
    position: absolute;
    top: -3%;
  }

  .comments {
    border-bottom: none;
  }

  .answer-timer {
    display: block !important;
    position: absolute;
    bottom: 10%;
  }

  .answer-timer .submit-your-answer {
    padding-bottom: 10px;

  }

  .session-page-user-reaches-be::-webkit-scrollbar {
    display: none !important;
  }

  .item-question .sit-sit-feugiat h3 {
    font-size: 21px !important;
    font-weight: 700 !important;
  }
}

@media screen and (max-width: 480px) {
  .page-wrap.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .iframe_video {
    width: 100% !important;
    /* height: 40% !important; */
    height: 400px !important;
  }

  .answer-timer {
    display: block !important;
  }

  /* .session-page-user-reaches-be{
    overflow-y: scroll;

} */
  .this-is-awesome,
  .udaysutaria,
  .yes-you-can {
    font-size: 14px !important;
  }

  .session-page-session-starts {
    /* height: 100vh; */
    padding: 0px;
    margin-bottom: 15px;
  }

  .this-is-awesome,
  .udaysutaria {
    font-size: 14px !important;
  }

  .success-summit-logo .logo-event-icon {
    left: 15px !important;
  }



  .comments {
    border-bottom: none;
    overflow: hidden;
    /* padding-bottom: 23px; */
    padding-bottom: 28px;
  }

  .submit_blg_dv {
    position: sticky;
    background: #fff;
    height: auto !important;
    z-index: 9;
  }

  .comments .form-group {
    position: absolute;
    min-width: 92%;
    bottom: 10px;
    background: #fff;
    padding: 10px;
    max-width: 100%;
    width: 100vh !important;
    left: -16px;
  }

  .submit_blg_dv {
    bottom: -6px;
  }


  .answer-timer {
    display: block !important;
    position: absolute;
    bottom: 10%;
  }

  .answer-timer .submit-your-answer {
    padding-bottom: 10px;

  }

  .session-page-user-reaches-be::-webkit-scrollbar {
    display: none !important;
  }
}

@media screen and (max-width: 320px) {
  .page-wrap.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .sec,
  .submit-your-answer {
    position: relative;
    top: 15px !important;
  }

  .iframe_video {
    width: 100% !important;
    height: 400px !important;
  }

  .answer-timer {
    display: block !important;
  }

  .this-is-awesome,
  .udaysutaria,
  .yes-you-can {
    font-size: 14px !important;
  }

  /* .session-page-user-reaches-be{
  overflow-y: scroll;

} */
  .this-is-awesome,
  .udaysutaria {
    font-size: 14px !important;
  }

  .session-page-session-starts {
    /* height: 100vh; */
    padding: 0px;
  }

  .answer-timer {
    font-size: 14px !important;
    margin-bottom: 15px;
  }

  .question {
    height: 401px;
  }

  .answer-timer {
    display: block !important;
    position: absolute;
    bottom: 10%;
  }

  .answer-timer .submit-your-answer {
    padding-bottom: 10px;

  }

  .session-page-user-reaches-be::-webkit-scrollbar {
    display: none !important;
  }
}

@media screen and (max-width: 290px) {
  .page-wrap.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .session-page-user-reaches-be::-webkit-scrollbar {
    display: none !important;
  }

  .answer-timer {
    display: block !important;
    position: absolute;
    bottom: 10%;
  }

  .answer-timer .submit-your-answer {
    padding-bottom: 10px;
  }

  .this-is-awesome,
  .udaysutaria {
    font-size: 14px !important;
  }

  .iframe_video {
    width: 100% !important;
    /* height: 40% !important; */
    height: 400px !important;
  }

  .question {
    height: 500px;
  }

  .answer-timer {
    font-size: 14px !important;
    margin-bottom: 15px;
  }

  .action {
    display: block;
  }

  .video {
    height: auto;
    min-width: 272px;
    min-height: 500px;
  }

  .session-page-session-starts {
    /* height: 100vh; */
    padding: 0px;
  }

  .item-question .sit-sit-feugiat h3 {
    font-size: 19px;
  }

  .nibh-id-vivamus {
    font-size: 14px;
  }

  .question {
    padding: 20px;
  }

  .session-page-session-starts {
    /* height: 100vh; */
    padding: 0px;
    padding: 0px;
  }
}

/* .iframe_video{
        height: 100% !important;
      }

      .session-video {
         height: 100% !important;
    } */

/* overlay_responsive design_css */

body {
  background-image: url("./assets/public/bg-dv.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}

body.mat-typography.blog {
  background-image: url("./assets/public/bg-dv1.png") !important;
  background-repeat: no-repeat;
  background-size: cover !important;
}

@media only screen and (min-device-width: 653px) and (max-device-width: 280px) and (orientation: landscape) {}

@media only screen and (min-device-width: 480px) and (max-device-width: 991px) and (orientation: landscape) {


  .thankyou_blg_dv h1 {
    font-size: 30px !important;
    font-weight: bold !important;
    margin-bottom: 20px !important;
    line-height: 48px !important;

  }

  .thankyou_blg_dv h6 {
    font-size: 14px !important;
  }

  .thankyou_blg_dv h3 {
    font-size: 19px !important;
    font-weight: 500 !important;
  }

  .thankyou_blg_dv .motivation {
    align-self: stretch;
    border-radius: 4px;
    background-color: transparent !important;
    flex-direction: row;
    padding: 100px 40px;
    text-align: center;
  }

  .header {
    padding: 10px !important;
  }

  .session-page-session-starts {
    overflow: scroll !important;
    height: inherit !important;
    gap: 10 !important;

  }

  .session-page-session-starts {
    height: auto !important;
  }

  .item-question .sit-sit-feugiat h3 {
    font-size: 20px;
  }

  .session-video {
    height: 100%;
    padding-bottom: 30px;
  }

  .overlay_question_dv .session-video {
    height: 100% !important;
    padding-bottom: 30px;
  }

  .question {
    padding: 20px;
    gap: 20px !important;
  }

  .nibh-id-vivamus {
    font-size: 14px !important;
  }

  .comments {
    width: 100%;
    margin-top: 20px;
    height: 68vh !important;

  }

  .session-page-user-reaches-be::-webkit-scrollbar {
    display: none !important;
  }

  .comments {
    border-bottom: none;
    overflow: hidden;
    padding-bottom: 23px;
  }

  .submit_blg_dv {
    position: sticky;
    background: #fff;
    height: auto !important;
    z-index: 9;
  }

  .comments .form-group {
    position: absolute;
    min-width: 100%;
    bottom: 11px;
    background: #fff;
    padding: 5px;
    max-width: 100%;
    width: 100vh !important;
    left: -17px;
  }

  .inner-comment-div {
    padding-bottom: 80px;
  }

  .page-wrap .main-preview-player {
    padding-left: 13px !important;
  }

  .item-question .sit-sit-feugiat h3 {
    font-size: 16px !important;
  }

  .overley-question-blg .overlay_question_dv {
    height: 80vh !important;
  }

  .item-question {
    flex: 1;
    flex-direction: column;
    gap: 15px;
  }

  .submit-your-answer {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
}

.overley-question-blg {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 99999 !important;
}

.overley-question-blg .overlay_question_dv {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.item-question .sit-sit-feugiat h3 {
  font-size: 25px;
  font-weight: bold !important;
}

.comments .form-inline {
  min-width: 100%;
}

.comments .comment-heading-dv {
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 999;
  display: flex !important;
  padding-bottom: 10px;

}

.submit_blg_dv {
  position: relative;
  background: #fff;
  width: 100%;
  height: 100px;
}

.yes-you-can {
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}

.yes-you-can p {
  margin-bottom: 0px !important;
}

.main-item-reply {
  width: 100%;
}

.message-p {
  margin-bottom: 0px !important;
}

.more-reply {
  color: #0d6efd !important;
  cursor: pointer !important;
}

@media only screen and (min-device-width: 667px) and (max-device-width: 320px) and (orientation: landscape) {
  .session-page-session-starts {
    height: calc(100% - auto);
  }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 991px) and (orientation: landscape) {
  .overley-question-blg {
    height: 100% !important;
  }

  .item-question .sit-sit-feugiat h3 {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .nibh-id-vivamus {
    font-size: 13px !important;
  }

  .item-question {
    flex: 1;
    flex-direction: column;
    gap: 8px !important;
  }

  .sec,
  .submit-your-answer {
    position: relative;
    line-height: 70%;
    text-decoration: none;
    font-size: 12px !important;
  }

}


.michelle-story.blog-michelle-story {
  background-color: #e2f6fe !important;
}

.anchor-link {
  color: #0d6efd !important;
  cursor: pointer;
  text-decoration: none !important;
}



/* 15th event scss */

:root {
  /* fonts */
  --font-open-sans: "Open Sans";
  --font-inter: Inter;

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-5xl: 24px;
  --font-size-13xl: 32px;
  --font-size-lg: 18px;
  --font-size-35xl: 54px;

  /* Colors */
  --color-white: #fff;
  --color-darkslateblue: #002359;
  --color-whitesmoke-100: #f5f5f5;
  --color-lemonchiffon: #fff5c2;
  --color-teal: #057c94;
  --color-gray: #16283b;
  --color-hotpink: #ea4c89;
  --color-darkslategray: #043e50;
  --color-gainsboro: #e4e5ec;
  --color-khaki: #ffd488;

  /* Gaps */
  --gap-5xl: 24px;
  --gap-13xl: 32px;

  /* Paddings */
  --padding-base: 16px;
  --padding-5xl: 24px;
  --padding-77xl: 96px;
  --padding-5xs: 8px;
  --padding-13xl: 32px;

  /* Border radiuses */
  --br-77xl: 96px;
  --br-base: 16px;
  --br-9xs: 4px;
}

/* New CSS add */
.container12,
.section15 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.container12 {
  width: 100%;
  align-items: flex-start;
  max-width: 1280px;
}

.section15 {
  align-self: stretch;
  align-items: center;
  padding: var(--padding-5xl) var(--padding-base);
}

.brand-product-logos3 {
  position: relative;
  width: 117.1px;
  height: 32px;
  object-fit: cover;
}

/* New CSS add */

@media screen and (max-width: 768px) {

  /* Event-15th-SFC-NC */
  .section1 .h1,
  .section7 .h11 {
    font-size: 35px;
  }

  .section1,
  .section2,
  .section3,
  .section7,
  .section8,
  .section9,
  .section8 {
    padding: 50px 15px !important;
  }

  .section1 .h3 {
    font-size: 23px;
  }

  .section1 .h5,
  .section7 .h31,
  .section7 .h51,
  .north-banner .h32 {
    font-size: 23px !important;
  }

  .section2 .h2,
  .banner-heading-cts {
    font-size: 28px !important;
  }

  .section2 .paragraphs,
  .implementing-these-tools,
  .section8 .paragraphs4,
  .implementing-these-tools1 {
    font-size: 19px;
  }

  .section3 .h2,
  .section8 .h23,
  .section9 .h23,
  .section8 .h51 {
    font-size: 28px;
  }

  .how-to-move-out-of-care-chaos li,
  .how-to-move-out-of-care-chaos1 li {
    font-size: 19px;
    margin-bottom: 0px;
  }

  /* Event-15th-SFC-NC */

  .section13 .h12 {
    font-size: 35px;
  }

  .section13,
  .section14 {
    padding: 50px 15px !important;
  }

  .section13 .h32,
  .section13 .h52 {
    font-size: 23px;
  }

  .section14 .h26,
  .section15 .h26 {
    font-size: 28px;
  }

  .section2 .paragraphs,
  .implementing-these-tools,
  .paragraphs8 {
    font-size: 19px;
  }

  .paragraphs8,
  .implementing-these-tools2 {
    font-size: 19px !important;
  }

  .section3 .h2 {
    font-size: 28px;
  }

  .paragraphs8 li {
    font-size: 19px !important;
    margin-bottom: 0px;
  }

  .section14 .h28 {
    font-size: 28px;
  }

  section {
    font-family: sans-serif !important;
  }

  /* event-15th-i-c-w-p.html */
}